import React, { PropsWithChildren } from 'react';
import { MainLayout } from './common/components/Layout/MainLayuot';
import { UserInfoBox } from './features/UserInfo/UserInfoBox';
import { useTranslation } from 'react-i18next';
import { useFilteredMenuItems } from './features/FilteredMenuItems/useFilteredMenuItems';

export interface AppMainLayoutProps extends PropsWithChildren {
  renderAfterMenuItems?: () => React.ReactElement;
}

export const AppMainLayout = ({ renderAfterMenuItems, children }: AppMainLayoutProps) => {
  const { t } = useTranslation();
  const menuItems = useFilteredMenuItems();

  return (
    <MainLayout
      header={t('sidebar.appName', { defaultValue: 'VidPrinter' })}
      menuItems={menuItems}
      renderBeforeMenuItems={() => <UserInfoBox />}
      renderAfterMenuItems={renderAfterMenuItems}>
      {children}
    </MainLayout>
  );
};
